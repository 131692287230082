export class TrainerModel {
  public _id: string;
  public email: string;
  public lastname: string;
  public firstname: string;
  constructor(_id: string, email: string, lastname: string, firstname: string) {
    this._id = _id;
    this.email = email;
    this.lastname = lastname;
    this.firstname = firstname;
  }
}
