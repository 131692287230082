import { Box } from "@mui/material";
import "./ProgramFooter.css";

export function ProgramFooter(){
    return (
        <Box sx={{color:"primary.main"}} className="footer">
            <hr/>
            <div>
                <p id="correspondance">Pour toute correspondance :</p>
                <p>MATCHERS – 120, Rue Adrien Proby – 34 090 Montpellier – contact@matchers.fr – 09 72 26 43 99</p>
            </div>
            <div className="footerInfoSociete">
                <p>CMC INSPIRATION S.A.S. –Via Innova – ZAE Espace Lunel Littoral – 177b Av Louis Lumière 34 400 Lunel, France</p>
                <p>SAS au capital de 15 000€ – R.C.S. Montpellier – Siren 849533849 – TVA intra. FR85849533849 - APE 6201Z – N° DA : 76-34-10490-34</p>
            </div>
        </Box>
    );
}