import {
  Button,
  Dialog,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DialogActions from "@mui/material/DialogActions";
import React from "react";
export default function DialogShowTokenTrainer(props: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  token: string;
  firstname: string;
}) {
  const [openTooltip, setOpenTooltip] = React.useState(false);

  const handleTooltipOpen = () => {
    navigator.clipboard.writeText(props.token).then(() => {
      setOpenTooltip(true);
    });
  };
  return (
    <Dialog
      sx={{ zIndex: 10000 }}
      open={props.open}
      keepMounted
      onClose={(e, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setOpen(false);
      }}
    >
      <DialogTitle>{"Token du trainer " + props.firstname}</DialogTitle>
      <DialogContent>
        <Stack direction="row">
          <IconButton onClick={handleTooltipOpen} style={{ zIndex: 10001 }}>
            <Tooltip
              PopperProps={
                {
                  style: {
                    zIndex: 10001,
                  },
                } as any
              }
              style={{ zIndex: 10001 }}
              title={openTooltip ? "Token copier" : "Copier le token"}
            >
              <ContentCopyIcon />
            </Tooltip>
          </IconButton>

          <TextField
            variant="filled"
            disabled={true}
            margin="dense"
            label="Token"
            type="text"
            value={props.token}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setOpen(false)}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}
