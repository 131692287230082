import { Stack } from "@mui/material";
import logo from "../../assets/logo_Seul.png";
import React from "react";
import "./error404.css";
export default function Error404Page() {
  return (
    <Stack alignItems={"center"}>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>

      <h1>404</h1>
      <h2>Page not found</h2>
    </Stack>
  );
}
