export class ClientModel {
  public _id: string;
  public email: string;
  public lastname: string;
  public firstname: string;
  public sector: string;
  public orientation: string;
  constructor(
    _id: string,
    email: string,
    lastname: string,
    firstname: string,
    sector: string,
    orientation: string
  ) {
    this._id = _id;
    this.email = email;
    this.lastname = lastname;
    this.firstname = firstname;
    this.sector = sector;
    this.orientation = orientation;
  }
}
