import React from "react";
import { TrainerModel } from "../models/Trainer.model";
import { ClientModel } from "../models/Client.model";

export enum RoleType {
  TRAINER = "Formateur",
  MAIN_TRAINER = "Formateur principal",
  ADMIN = "Matchers",
  CLIENT = "Client",
}

export class User {
  public user: ClientModel | TrainerModel | null;
  public role: RoleType;

  constructor(user: ClientModel | TrainerModel | null, role: RoleType) {
    this.user = user;
    this.role = role;
  }
}
export const UserContext = React.createContext<
  [User | null, React.Dispatch<React.SetStateAction<User | null>>]
>(null!);
