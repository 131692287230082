import axios from "axios";

export function AuthInterceptor(token: string) {
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.get["Access-Control-Allow-Origin"] = "*";
  axios.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers!.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}
