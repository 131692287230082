import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Button, Dialog, TextField } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import React, { useContext, useState } from "react";
import { TrainerModel } from "../../models/Trainer.model";
import { ProgramService } from "../../service/program/Program.service";
import { ProgramContext } from "../../context/program.context";
import DialogShowTokenTrainer from "../DialogShowTokenTrainer/DialogShowTokenTrainer";
export default function DialogAddTrainer(props: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [program, setProgram] = useContext(ProgramContext);

  const [openDialogToken, setOpenDialogToken] = useState(false);
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [token, setToken] = useState("");
  const handleChangeFirstname = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFirstname(event.target.value);
  };
  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handleChangeLastname = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastname(event.target.value);
  };

  const addTrainer = () => {
    props.setOpen(false);
    let trainer = new TrainerModel("", email, lastname, firstname);
    ProgramService.addTrainer(trainer)
      .then((result) => {
        if (program) {
          trainer._id = result.userId;
          setToken(result.token);
          let copyProgram = program.copy();

          copyProgram.addTrainer(trainer);
          setProgram(copyProgram);
          props.setOpen(false);
          setOpenDialogToken(true);
        } else {
          console.log("Missing program");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Dialog
        sx={{ zIndex: 10000 }}
        open={props.open}
        aria-describedby="alert-dialog-slide-description"
        keepMounted
        onClose={(e, reason) => {
          if (reason && reason === "backdropClick") return;
          props.setOpen(false);
        }}
      >
        <DialogTitle>{"Ajouter un formateur"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Adresse email du formateur"
            type="email"
            fullWidth
            variant="standard"
            value={email}
            onChange={handleChangeEmail}
            required={true}
          />
          <TextField
            margin="dense"
            label="Nom du formateur"
            type="text"
            fullWidth
            variant="standard"
            value={lastname}
            required={true}
            onChange={handleChangeLastname}
          />
          <TextField
            margin="dense"
            label="Prénom du formateur"
            type="text"
            fullWidth
            variant="standard"
            value={firstname}
            required={true}
            onChange={handleChangeFirstname}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.setOpen(false)}>Annuler</Button>
          <Button onClick={addTrainer}>Valider</Button>
        </DialogActions>
      </Dialog>
      <DialogShowTokenTrainer
        open={openDialogToken}
        setOpen={setOpenDialogToken}
        token={token}
        firstname={firstname}
      />
    </>
  );
}
