import { TrainerModel } from "../../models/Trainer.model";
import { ClientModel } from "../../models/Client.model";
import axios from "axios";
import { plainToInstance } from "class-transformer";
import { RoleType, User } from "../../context/user.context";

export class UserService {
  public static getUser(): Promise<User> {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + "/user")
        .then((response) => {
          let user = null;
          let userJson = response.data.user;
          let role = RoleType.CLIENT;
          switch (response.data.role) {
            case "MAIN_TRAINER":
              user = plainToInstance(TrainerModel, userJson);
              role = RoleType.MAIN_TRAINER;
              break;
            case "TRAINER":
              user = plainToInstance(TrainerModel, userJson);
              role = RoleType.TRAINER;
              break;
            case "CLIENT":
              user = plainToInstance(ClientModel, userJson);
              role = RoleType.CLIENT;
              break;
            case "ADMIN":
              user = null;
              role = RoleType.ADMIN;
              break;
          }
          // @ts-ignore
          resolve(new User(user, role));
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
